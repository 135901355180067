<template>
  <div>
    <b-modal
      v-model="showModal"
      ref="modal"
      centered
      size="lg"
      hide-footer
      footer-class="pt-0 border-0"
    >
      <template #modal-header="{ close }">
        <div class="modal-title">คูปองสะสม</div>
        <button type="button" aria-label="Close" class="close" @click="close">
          ×
        </button>
      </template>
      <div class="bg-white border-red">
        <b-row>
          <b-col>
            <b-table
              v-if="!isMobile"
              responsive
              striped
              hover
              :fields="fields"
              :items="items"
              :busy="isBusy"
              show-empty
              empty-text="No matching records found"
              class="table-main"
              :no-border-collapse="false"
              sticky-header
            >
              <template v-slot:cell(select)="data">
                <b-button
                  variant="outline-primary-color"
                  class="btn-modal btn-hover"
                  @click="unselectRow(data.item)"
                  :disabled="isBusy"
                  v-if="selectedList.includes(data.item.id)"
                >
                  ยกเลิก
                </b-button>
                <b-button
                  variant="primary-color"
                  class="btn-modal btn-hover"
                  @click="selectRow(data.item)"
                  :disabled="isBusy"
                  v-else
                >
                  เลือก
                </b-button>
              </template>
              <template v-slot:cell(promotion_value)="data">
                <div>
                  {{ data.item.promotion_value | numeral('0,0.00') }}
                  {{
                    data.item.discount_type == 'percent'
                      ? '%'
                      : data.item.discount_type == 'amount'
                      ? 'บาท'
                      : data.item.discount_type == 'point'
                      ? 'แต้ม'
                      : ''
                  }}
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
            <div v-else>
              <div class="py-2 detail-panel mb-5 mt-2">
                <div
                  class="product-card"
                  v-for="(c, index) in items"
                  :key="index"
                  @click="selectRow(c)"
                >
                  <div class="product-card-detail">
                    <div>
                      <strong>{{ c.code || '-' }}</strong>
                      <p class="text-general">{{ c.name }}</p>
                    </div>
                    <div>
                      <p>
                        {{ c.promotion_value | numeral('0,0.00') }}
                        {{ c.discount_type === 'percent' ? '%' : 'บาท' }}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="p-2 m-2 bg-white no-data-item"
                  v-if="items.length == 0"
                >
                  ไม่พบข้อมูล
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <ModalNote ref="modalNote" :branchId="branchId" />
  </div>
</template>

<script>
import InputTextArea from '@/components/inputs/InputTextArea';
import OtherLoading from '@/components/loading/OtherLoading';
import InputText from '@/components/inputs/InputText';
import { mapGetters } from 'vuex';
import ModalNote from '@/components/customer/ModalNote';

export default {
  components: {
    InputTextArea,
    OtherLoading,
    InputText,
    ModalNote
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      user: null,
      fields: [
        {
          key: 'select',
          label: ''
        },
        {
          key: 'code',
          label: 'รหัสคูปอง'
        },
        {
          key: 'name',
          label: 'ชื่อคูปอง'
        },
        {
          key: 'promotion_value',
          label: 'ส่วนลด'
        }
      ],
      now: null,
      items: [],
      isBusy: false,
      rows: 0,
      selectedList: [],
      selecedHeaderDiscountIdList: []
    };
  },
  computed: {
    ...mapGetters({
      dateTimeFix: 'getDateTimeFix',
      branchId: 'getBranchId'
    })
  },
  created: async function () {
    this.now = this.$moment().format('YYYY-MM-DDTHH:mm:ss');
  },
  methods: {
    getList: async function () {
      this.isBusy = true;

      let datetime =
        this.dateTimeFix || this.$moment().format('YYYY-MM-DDTHH:mm:ss');

      this.isLoading = true;
      await this.axios
        .get(
          `${this.$baseUrl}/coupon/get_list/${
            this.user.user_guid || ''
          }/${datetime}/${this.branchId}`
        )
        .then(async data => {
          if (data.result == 1) {
            this.items = data.detail;
            this.rows = data.total_count;

            this.isLoading = false;
          }
        });

      this.isBusy = false;
    },
    async show(user, selecedHeaderDiscountIdList) {
      this.user = user;
      this.selectedList = [...selecedHeaderDiscountIdList];
      this.showModal = true;
      await this.getList();
    },
    hide() {
      this.showModal = false;
    },
    async selectRow(item) {
      this.selectedList.push(item.id);
      await this.addCoupon(item);
    },
    async unselectRow(item) {
      this.selectedList = await this.selectedList.filter(id => id != item.id);
      await this.deleteCoupon(item);
    },
    addCoupon(item) {
      this.$emit('addCoupon', item);
      this.hide();
    },
    deleteCoupon(item) {
      this.$emit('deleteCoupon', item);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  color: #fff;
  width: 100%;
  background-color: var(--primary-color);
}
::v-deep .modal-body {
  padding: 1rem 1rem;
}
.btn-primary .modal-body {
  margin: auto;
  text-align: center;
}
::v-deep .nav-tabs li:nth-child(2) {
  border-left: 1px solid white;
  border-right: 1px solid white;
}

.product-card {
  min-height: 88px;
  cursor: pointer;
  color: #333333;
  background-color: #fff;
  padding: 0.5rem;
  margin: 0.5rem;

  .product-card-detail {
    height: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.no-data-item {
  min-height: 88px;
  display: flex;
  justify-content: center;
  font-size: 14px;
  color: #bdbdbd;
  align-items: center;
  background-color: #fff;
  padding: 0.5rem;
  margin: 0.5rem;
}

@media (max-width: 768px) {
  ::v-deep .div-input {
    width: 100%;
  }
  ::v-deep .modal-dialog {
    margin: 0px;
    max-width: 100vw !important;
    .modal-content {
      width: 100vw;
      height: 100vh;
      .modal-body {
        overflow-y: auto;
      }
    }
  }
}
</style>
