import { render, staticRenderFns } from "./ModalCoupon.vue?vue&type=template&id=021283cd&scoped=true"
import script from "./ModalCoupon.vue?vue&type=script&lang=js"
export * from "./ModalCoupon.vue?vue&type=script&lang=js"
import style0 from "./ModalCoupon.vue?vue&type=style&index=0&id=021283cd&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "021283cd",
  null
  
)

export default component.exports